import React, { useEffect, useState } from 'react';
import './LandingPage.css';

const texts = [
  "Engineering", "Coding", "DevOps", "Crafting Code", "Speed in Shipping",
  "Code Refactoring", "Algorithm Optimisation", "Code Review Precision",
  "Debugging Efficiency", "Scalability Solutions", "Security Protocols",
  "Performance Tuning", "Automating Tests", "Database Scaling", "Query Optimisation",
  "CI/CD Pipeline Automation"
];

const LandingPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleClick = () => {
    window.open('https://x.com/icamp', '_blank');
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-900 px-4">
      <div className="absolute top-4 left-4 text-white font-bold text-2xl md:text-4xl lg:text-8xl logo">
        10x<span className="blink">_</span>
      </div>
      <div className="flex flex-col items-center justify-center w-full text-white font-bold p-4 relative text-center lg:flex-col lg:gap-4">
        <div className="w-full lg:w-auto lg:text-center pr-0 lg:pr-4">
          <span className="text-4xl md:text-6xl lg:text-9xl">10x</span>
        </div>
        <div className="w-full lg:w-auto lg:pl-4 mt-4 lg:mt-8">
          <span className="textEffect text-2xl md:text-4xl lg:text-6xl" data-text={texts[currentIndex]}>
            {texts[currentIndex]}
          </span>
        </div>
      </div>
      <button 
        onClick={handleClick} 
        className="mt-8 px-4 py-2 md:px-6 md:py-3 lg:px-8 lg:py-4 bg-white text-gray-900 font-semibold rounded-lg shadow-md hover:bg-yellow-200 transition-colors"
      >
        DM X To Get Early Access
      </button>
    </div>
  );
};

export default LandingPage;
